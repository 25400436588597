import React from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby-link"
import LayoutDark from "./shared/layout/layout-dark"
import "../../static/assets/scss/pages/page.scss"
const routes = require("../types/routes")

export default class GetStarted extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
  }

  onBackToHome() {
    navigate("/")
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic"}>
            <div className={"container contact-us"}>
              <div className={"row"}>
                <div className={"column centered"}>
                  <div className={"content"}>
                    <p className={"tag"}>Page not found</p>
                    <h2 className={"title"}>404</h2>
                    <p className={"description"}>
                      Oops! The page you are looking for has been removed or
                      relocated
                    </p>
                    <button
                      type={"button"}
                      ref={this.button}
                      onClick={e => this.onBackToHome(e)}
                    >
                      Go back to home
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
